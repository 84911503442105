body, html, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.galaxy {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, #0B0B3B 0%, #000000 100%);
  overflow: hidden;
  z-index: 1;
}

.stars {
  width: 4px;
  height: 4px;
  background: #FFFFFF;
  position: absolute;
  border-radius: 50%;
  animation: twinkle 2s infinite;
}

@keyframes twinkle {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}

.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  overflow: hidden;
  position: relative;
}

.App-header {
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 3;
}

.App-content {
  position: relative;
  z-index: 2;
  /* Estilos adicionales para el contenido */
}

body, html, #root, .App {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  .galaxy {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at center, #0B0B3B 0%, #000000 100%);
    overflow: hidden;
    z-index: 1;
  }
  
  .stars {
    width: 4px;
    height: 4px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 50%;
    animation: twinkle 2s infinite;
  }
  
  @keyframes twinkle {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1.2);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
    }
  }
  
  .App {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    overflow: hidden;
    position: relative;
  }
  
  .App-header {
    text-align: center;
    color: #fff;
    position: relative;
    z-index: 3;
  }
  
  .App::before,
  .App::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    animation: moveStars 50s linear infinite;
    z-index: 0;
  }
  
  .App::before {
    background-image: 
      radial-gradient(2px circle, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  }
  
  .App::after {
    background-image: 
      radial-gradient(2px circle, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
    animation-delay: 25s;
  }
  
  @keyframes moveStars {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }
  .App {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    overflow: hidden;
    position: relative;
  }
  
  .App-header {
    text-align: center;
    color: #fff;
    position: relative;
    z-index: 3;
  }
  
  .App::before,
  .App::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    animation: moveStars 50s linear infinite;
    z-index: 1;
  }
  
  .App::before {
    background-image: 
      radial-gradient(5px circle at 10% 20%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(5px circle at 40% 30%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(5px circle at 70% 40%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(5px circle at 80% 50%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(5px circle at 20% 60%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(5px circle at 50% 70%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(5px circle at 60% 80%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(5px circle at 30% 90%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(50px circle at 50% 50%, #5C6BC0, #00000000),
      radial-gradient(70px circle at 80% 20%, #EF5350, #00000000);
  }
  
  .App::after {
    background-image: 
      radial-gradient(10px circle at 10% 10%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(10px circle at 30% 20%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(10px circle at 60% 30%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(10px circle at 90% 40%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(7px circle at 40% 50%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(7px circle at 20% 60%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(7px circle at 80% 70%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(7px circle at 70% 80%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(10px circle at 60% 90%, #fff, rgba(255, 255, 255, 0)),
      radial-gradient(120px circle at 30% 70%, #66BB6A, #00000000);
    animation-delay: 25s;
  }
  
  @keyframes moveStars {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }